import axios from "axios";
import cx from "classnames";
import { Field, Form, Formik } from "formik";
import React, { FC, useState } from "react";
import * as yup from "yup";

import { ButtonCta } from "../../../components/Cta";
import { InputText } from "../../../components/Form/InputText";
import SEO from "../../../components/seo";

import * as formStyles from "../../../styles/shared/form.module.scss";
import * as global from "../../../styles/global.module.scss";
import * as quizStyles from "../../../styles/shared/quiz.module.scss";

interface Props {
  data: {
    [key: string]: any;
  };
  location: Location;
}

interface FormProps {
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
}

const DiscoverStep2a: FC<Props> = (props: Props) => {
  const { location } = props;
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validationSchema = yup.object().shape({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
  });
  const onSubmitForm = (values: FormProps) => {
    setIsSubmitting(true);
    axios
      .post(process.env.GATSBY_GUIDE_DISCOVER_FORM_URL, values)
      .then(() => {
        setIsSuccess(true);
      })
      .catch(() => {
        setIsSuccess(false);
      })
      .then(() => {
        setIsSubmitting(false);
      });
  };
  return (
    <>
      <SEO
        meta={[
          {
            name: "og:url",
            content: location.href,
          },
        ]}
        title="Constant - assistant shopping 100% éco-responsable"
      />
      <div className={cx(global.pageWrapper)}>
        <div className={quizStyles.introContainer}>
          <h2 className={cx(quizStyles.title, global.bigTitle, global.title)}>
            <u>La solution faite pour vous</u> : <br />
            Un guide personalisé, centré sur votre style général.
          </h2>
        </div>
        <div className={cx(quizStyles.container, global.text)}>
          <p>
            Cela vous permettra de découvrir 5 marques éco-responsables qui vous
            correspondent, sans la galère d'avoir à les chercher et avec la
            certitude qu'elles soient vraiment éthiques.
          </p>

          <p>
            Grâce à ce guide, vous pourrez mettre un pied dans l'univers de la
            mode éco-responsable facilement.
          </p>

          <p>
            Vous êtes intéressé.e par ce guide sur-mesure&nbsp;? Dites-le moi
            avec ce mini formulaire&nbsp;!
            <br /> Je vous recontacterai pour que l'on en discute plus en
            détail.
          </p>
        </div>
        <div className={quizStyles.container}>
          <div
            className={cx(global.title, {
              [quizStyles.hideOnInitial]: !isSuccess,
              [quizStyles.showOnSucsess]: isSuccess,
            })}
          >
            <p>Votre demande à bien été envoyée.</p>
            <p>Je vous recontacte rapidement !</p>
          </div>
          <div
            className={cx({
              [quizStyles.hideOnSucsess]: isSuccess,
            })}
          >
            <Formik
              onSubmit={onSubmitForm}
              initialValues={{
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
              }}
              validationSchema={validationSchema}
              validateOnChange={true}
            >
              {() => (
                <Form>
                  <div className={formStyles.formRow}>
                    <div className={formStyles.formGroup}>
                      <div className={cx(formStyles.formGroupContent)}>
                        <Field
                          component={InputText}
                          name="firstname"
                          placeholder="Votre prénom *"
                        />
                      </div>
                      <div className={cx(formStyles.formGroupContent)}>
                        <Field
                          component={InputText}
                          name="lastname"
                          placeholder="Votre nom *"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={formStyles.formRow}>
                    <div className={formStyles.formGroup}>
                      <div className={cx(formStyles.formGroupContent)}>
                        <Field
                          component={InputText}
                          type="tel"
                          name="phone"
                          placeholder="Votre nº de portable *"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={formStyles.formRow}>
                    <div className={formStyles.formGroup}>
                      <div className={cx(formStyles.formGroupContent)}>
                        <Field
                          component={InputText}
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Votre email *"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <ButtonCta
                      disabled={isSubmitting}
                      btnClassname={cx({ [formStyles.disabled]: isSubmitting })}
                      type="submit"
                      className={"submitBtn"}
                      label={
                        isSubmitting
                          ? "Envoi en cours..."
                          : "Le guide m'intéresse"
                      }
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscoverStep2a;
